<template>
  <div>
    <div class="header">
      <div class="left container">
        <div class="clickable city">
          <i class="iconfont icon-map"></i>
          <span>{{ city }}</span>
        </div>
        <div
          class="clickable city"
          @click="citySelectorVisible = !citySelectorVisible"
        >
          <i class="iconfont icon-refresh"></i>
          <span>切换城市</span>
        </div>
        <DateTime />
      </div>
    </div>

    <div class="city-list" v-if="citySelectorVisible">
      <div class="left container">
        <div
          class="clickable item"
          v-for="item in cityList"
          :key="item"
          @click="onChangeCity(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>

    <div class="subtitle">
      <div class="container">
        <div class="between">
          <img class="logo" src="../../assets/logo.png" alt="Logo" />
          <div class="left search">
            <input
              type="text"
              placeholder="请输入您需要查询的关键字"
              v-model="searchValue"
              @keydown.enter="onSearch"
            />
            <i class="clickable iconfont icon-search" @click="onSearch"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="menu">
      <div class="between container">
        <router-link
          v-for="item in menu"
          :key="item.title"
          :to="item.path"
          :class="active === item.path ? 'active' : ''"
          >{{ item.title }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import DateTime from "./date-time";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    DateTime,
  },

  data() {
    return {
      citySelectorVisible: false,
      cityList: [
        "南京",
        "无锡",
        "苏州",
        "常州",
        "徐州",
        "泰州",
        "扬州",
        "淮安",
        "南通",
        "连云港",
        "宿迁",
        "镇江",
        "盐城",
      ],

      menu: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "气象新闻",
          path: "/weather-news",
        },
        {
          title: "预报实况",
          path: "/weather-forecast",
        },
        {
          title: "防灾减灾",
          path: "/disaster",
        },
        {
          title: "环境气象",
          path: "/environment",
        },
        {
          title: "交通气象",
          path: "/traffic",
        },
        {
          title: "旅游气象",
          path: "/travel",
        },
        {
          title: "农业气象",
          path: "/farming",
        },
        {
          title: "专业服务",
          path: "/major",
        },
        {
          title: "热点专题",
          path: "/hot",
        },
        {
          title: "特色产品",
          path: "/products",
        },
      ],
      active: "/",

      searchValue: "",
    };
  },

  computed: {
    ...mapState("city", ["city"]),
  },

  watch: {
    $route() {
      this.active = this.$route.path;
    },
  },
  mounted() {
    this.active = this.$route.path;
  },
  methods: {
    ...mapMutations("city", ["setCity"]),
    onChangeCity(city) {
      this.setCity(city);
      this.citySelectorVisible = false;
    },

    ...mapMutations("search", ["setKeyword"]),

    onSearch() {
      if (!this.searchValue) {
        this.$message.error("请输入查询关键字");
        return;
      }
      this.setKeyword(this.searchValue);
      this.$router.push("/search");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  background-color: var(--theme-color);
  color: #fff;
  font-size: 12px;

  .container {
    line-height: 36px;

    .city {
      margin-right: 12px;

      .iconfont {
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }
}

.city-list {
  background-color: #fff;
  .container {
    padding: 8px 56px;
    .item {
      color: var(--theme-color);
      font-weight: bold;
      margin-right: 24px;
    }
  }
}

.subtitle {
  background-color: #fff;
  .between {
    padding: 8px 0;
    border-bottom: 4px solid var(--theme-color);
  }

  .search {
    input {
      border: 1px solid #dadada;
      width: 200px;
      padding: 4px 8px;

      &::placeholder {
        color: #999;
      }
    }
    i {
      color: var(--theme-color);
      font-weight: bold;
      font-size: 18px;
      margin-left: 8px;
    }
  }
}

.menu {
  background-color: #fff;
  padding: 8px 0;
  a {
    color: var(--text-color);
  }
  .active {
    color: var(--theme-color);
    font-weight: bold;
  }
}
</style>