<template>
    <div class="layout">
        <Header />
        <div class="body">
            <router-view></router-view>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from './components/header'
import Footer from './components/footer'
export default {
    components: {
        Header,
        Footer,
    }
}
</script>

<style lang="less" scoped>
.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .body {
        margin: 12px 0;
        flex: 1;
    }
}
</style>