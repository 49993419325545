<template>
    <div class="date-time">
        {{dateTime}}
    </div>
</template>

<script>
export default {
    data() {
        return {
            dateTime: "",
        };
    },

    mounted() {
        this.getDateTime();
    },

    methods: {
        getDateTime() {
    const now = new Date(new Date().getTime() + 3600 * 24);
    const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const date = now.getDate();
 
            const day = now.getDay();
            const weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
            const week = weeks[day];

            this.dateTime = `${year}年${month}月${date}日 ${week} ${this.setLunarCalendar( now.toLocaleString('zh-Hans-u-ca-chinese'))}` 
        },

        setLunarCalendar(str) {
            const arr = str.split(' ')
            if (arr.length > 1) {
                const value = arr[0].substring(5);
                const calendar = ['初一', '初二', '初三', '初四', '初五', '初六', '初七', '初八', '初九', '初十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十', '廿一', '廿二', '廿三', '廿四', '廿五', '廿六', '廿八', '廿九','三十']
                return '农历' + value.replace(/[0-9]+/g, num => {
                   return calendar[parseInt(num) - 1];
                })
            } else {
                return ''
            }
        }

   
    },
};
</script>

<style lang="less" scoped>
.date-time {
    margin-left: auto;
}
</style>